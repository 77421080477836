import { render, staticRenderFns } from "./ChatWithCategories.vue?vue&type=template&id=2289a7ce&scoped=true&"
import script from "./ChatWithCategories.vue?vue&type=script&lang=js&"
export * from "./ChatWithCategories.vue?vue&type=script&lang=js&"
import style0 from "./ChatWithCategories.vue?vue&type=style&index=0&id=2289a7ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2289a7ce",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QList from 'quasar/src/components/item/QList.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDate from 'quasar/src/components/date/QDate.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu,QPage,QItem,QItemSection,QSpinner,QList,QRadio,QIcon,QInput,QSeparator,QDate});qInstall(component, 'directives', {Ripple});
